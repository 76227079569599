import React from 'react';
import PropTypes from 'prop-types';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { ImmersiveLeadDefaultLayout } from './ImmersiveLeadDefaultLayout';
import { ImmersiveLeadTextLayout } from './ImmersiveLeadTextLayout';

const ImmersiveLeadInner = ({ content, isAboveTheFold }) => {
  const hasImage =
    !!content.immersiveLead.entity?.immersiveImage?.entity?.mediaImage?.url;
  const hasVideo = !!content.immersiveLead.entity?.immersiveVideo?.entity;
  const { layout, backgroundColor } = content.immersiveLead.entity;
  const backgroundColorConfig = {
    gray: { background: '#f2f2f2', color: '#000' },
    black: { background: '#000', color: '#fff' },
    white: { background: '#fff', color: '#000' }
  };
  const color = backgroundColor || content.backgroundColor;
  const colors = {
    color: color
      ? `${backgroundColorConfig[color].color} !important`
      : '#000 !important',
    background: color ? backgroundColorConfig[color].background : null
  };

  // If no background color is selected, text color is set to black, and
  // background is set to white. But when an image or video is used as background,
  // this leads to black text over that image/video. Hence, here we control that
  // case and set white as text color.
  if ((hasImage || hasVideo) && !color) {
    colors.color = colors.color.replace('#000', '#fff');
  }

  return (
    <>
      {layout === 'text' ? (
        <ImmersiveLeadTextLayout
          content={content}
          isAboveTheFold={isAboveTheFold}
          colors={colors}
        />
      ) : (
        <ImmersiveLeadDefaultLayout
          content={content}
          isAboveTheFold={isAboveTheFold}
          colors={colors}
        />
      )}
    </>
  );
};

ImmersiveLeadInner.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool.isRequired
};

export const ImmersiveLead = ParagraphWrapper(ImmersiveLeadInner);
