import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { ngartImgContCopy } from '../../Styles/articleImage';

const ImmersiveLeadCaptionInner = ({ content }) => {
  const { hideCaption, hideCredit, immersiveImage, immersiveVideo } =
    content.immersiveLead.entity;

  const imageCaption = immersiveImage?.entity.caption;

  const videoCaption = immersiveVideo?.entity.promoTitle;

  const hasImage = !!immersiveImage?.entity?.mediaImage?.url;
  const hasVideo = !!immersiveVideo?.entity;
  const styles = {
    mediaInfo: css`
      padding: 11px 2.5rem 0.5rem;
      max-width: 1160px;
      margin-top: 11px;
      padding-left: ${theme.spacing('l')};
      color: #000;
      ${theme.font.family('regularWeb')};
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.1px;
      line-height: 20px;
      position: relative;
      cursor: auto;
      a:any-link {
        border-bottom: 2px solid #fc0;
      }
      p {
        color: #000;
        ${theme.font.family('regularWeb')};
        font-weight: 400;
        font-size: 0.875rem;
        letter-spacing: 0.1px;
        line-height: 20px;
      }
    `,
    imageInfo: css`
      display: block;
      ${theme.mq.small_desktop} {
        display: ${hasImage && hasVideo ? 'none' : 'block'};
      }
      color: #000;
      p {
        color: #000;
      }
    `,
    videoInfo: css`
      display: ${hasImage && hasVideo ? 'none' : 'block'};
      ${theme.mq.small_desktop} {
        display: block;
      }
      color: #000;
      p {
        color: #000;
      }
    `
  };
  return (
    <>
      {(!hideCaption || !hideCredit) && (
        <div className="ngart-img__cont" css={[styles.mediaInfo]}>
          {!hideCaption && (imageCaption || videoCaption) && (
            <>
              {hasImage && (
                <div
                  css={[ngartImgContCopy, styles.imageInfo]}
                  className="ngart-img__cont__copy"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: imageCaption
                  }}
                />
              )}
              {hasVideo && (
                <div
                  css={[ngartImgContCopy, styles.videoInfo]}
                  className="ngart-img__cont__copy"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: videoCaption
                  }}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

ImmersiveLeadCaptionInner.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};

export const ImmersiveLeadCaption = ParagraphWrapper(ImmersiveLeadCaptionInner);
