import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { t } from '../../../domain/services/configService';
import {
  ngartImgContAuthor,
  ngartImgContStrong
} from '../../Styles/articleImage';
import { PhotographersLink } from '../../responsive/atoms/PhotographersLink';
import { ParagraphWrapper } from '../ParagraphWrapper';

const ImmersiveLeadCreditInner = ({ content }) => {
  const { hideCredit, immersiveImage, immersiveVideo } =
    content.immersiveLead.entity;

  const videoCredit = immersiveVideo?.entity.author;

  const hasImage = !!immersiveImage?.entity?.mediaImage?.url;
  const hasVideo = !!immersiveVideo?.entity;
  const styles = {
    videoCredit: css`
      margin-top: 10px;
      display: ${hasImage && hasVideo ? 'none' : 'block'};
      ${theme.mq.small_desktop} {
        display: block;
      }
      color: #000;
    `
  };

  return (
    <>
      {!hideCredit &&
        (immersiveImage?.entity.photographers?.length > 0 || videoCredit) && (
          <>
            {hasImage && (
              <PhotographersLink
                photographers={immersiveImage?.entity.photographers}
                color={{ text: '#000', link: '#000' }}
              />
            )}
            {hasVideo && (
              <div
                css={[ngartImgContAuthor, styles.videoCredit]}
                className="ngart-img__cont__author"
              >
                {`${t('by')} `}
                <span
                  className="ngart-img__cont--strong"
                  css={ngartImgContStrong}
                >
                  {videoCredit && videoCredit.replace(/<\/?[^>]+(>|$)/g, '')}
                </span>
              </div>
            )}
          </>
        )}
    </>
  );
};

ImmersiveLeadCreditInner.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};

export const ImmersiveLeadCredit = ParagraphWrapper(ImmersiveLeadCreditInner);
